.date-picker .react-datepicker,
.date-picker .react-datepicker__month-container,
.custom-phone-input .react-tel-input .form-control {
  @extend .w-100;
}
.date-picker .react-datepicker-popper {
  @extend .end-0;
  z-index: 10;
}
.date-picker .react-datepicker__month-container {
  font-size: 1rem;
}

.date-picker .react-datepicker__current-month,
.date-picker .react-datepicker-time__header,
.date-picker .react-datepicker-year-header {
  font-size: 1.4rem;
}

.date-picker .react-datepicker__day-name,
.date-picker .react-datepicker__day,
.date-picker .react-datepicker__time-name {
  width: 2.4rem;
}

.date-picker .react-datepicker__day--selected,
.date-picker .react-datepicker__day--in-selecting-range,
.date-picker .react-datepicker__day--in-range,
.date-picker .react-datepicker__month-text--selected,
.date-picker .react-datepicker__month-text--in-selecting-range,
.date-picker .react-datepicker__month-text--in-range,
.date-picker .react-datepicker__quarter-text--selected,
.date-picker .react-datepicker__quarter-text--in-selecting-range,
.date-picker .react-datepicker__quarter-text--in-range,
.date-picker .react-datepicker__year-text--selected,
.date-picker .react-datepicker__year-text--in-selecting-range,
.date-picker .react-datepicker__year-text--in-range,
.date-picker .react-datepicker__day--keyboard-selected,
.date-picker .react-datepicker__month-text--keyboard-selected,
.date-picker .react-datepicker__quarter-text--keyboard-selected,
.date-picker .react-datepicker__year-text--keyboard-selected {
  background-color: $purple-100;
}

.date-picker .react-datepicker__current-month,
.date-picker .react-datepicker-time__header,
.date-picker .react-datepicker__day-name,
.date-picker .react-datepicker__header {
  color: $white;
}

.date-picker .react-datepicker__header {
  background: linear-gradient(90deg, #3441ba 0%, #5f3bcc 67.71%, #6c3abb 100%);
  border-color: $purple;
}

.date-picker .react-datepicker__week {
  @extend .d-flex, .justify-content-evenly;
}

.date-picker .react-datepicker__year-read-view--down-arrow,
.date-picker .react-datepicker__month-read-view--down-arrow,
.date-picker .react-datepicker__month-year-read-view--down-arrow,
.date-picker .react-datepicker__navigation-icon::before {
  top: 8px;
  border-color: $white;
}
.date-picker .react-datepicker__navigation-icon {
  top: 1px;
}
