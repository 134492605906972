@import '~bootstrap/dist/css/bootstrap.css';
@import 'react-toastify/dist/ReactToastify.css';
@import './assets/fonts/font-awesome.min.scss';
@import 'react-datepicker/dist/react-datepicker.css';
@import './assets/fonts/bs-icons/bootstrap-icons.css';
@import './assets/css/calendar.scss';
@import './assets/css/style.scss';
@import './assets/css/responsive.scss';
.comma-list span:not(:last-child)::after {
  content: ', ';
  margin-right: 3px;
}
/* Style  Css */
body,
html {
  overflow-y: hidden;
  text-transform: lowercase;
}

.page_body .left_side nav.navigation {
  overflow-y: auto;
  height: calc(100vh - 75px);
}

.fs-14 {
  font-size: 14px;
}

.wh-full {
  white-space: nowrap;
}

.table tbody tr.active {
  background: #ff000073;
}

.page_body .left_side .desk_logo > a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--white-color);
}

.page_body .left_side .desk_logo > a img {
  max-width: 65%;
}

.page_body .left_side .desk_logo > a span {
  font-size: 20px;
  font-weight: 600;
}

.table tbody tr td .btn-icon i {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table tbody tr td .btn-icon i::before {
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table thead tr td input,
.table thead tr th input {
  display: block;
}

.form-control.otpField.otp-input {
  width: auto;
  height: 34px;
  border-radius: 6px;
  border: solid 1px #c5c5c5;
  text-align: center;
  font-size: 16px;
  display: inline-block;
}

.user_bio li span > i.fa-copy {
  cursor: pointer;
}

pagination-controls {
  text-align: center;
  margin-top: 25px;
  display: block;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}

.pie-chart .chart {
  margin: 40px 0;
}

.table tbody tr td .btn-icon.text {
  width: auto;
  white-space: nowrap;
  padding: 0px 10px;
  font-size: 14px;
}

.table tbody tr td span.no_image,
tr.cdk-drag td img,
.table tbody tr td img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  font-size: 100%;
  display: inline-flex;
}

.table > thead > tr > th .sort {
  text-align: center;
  display: inline-flex;
  position: relative;
}

.table > thead > tr > th .sort .button {
  color: var(--white-color);
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.table > thead > tr > th .sort .button i {
  line-height: normal;
  pointer-events: none;
}

.pd_image_box {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 20px;
}

.pd_image_box.userPage .no_image {
  min-height: 250px;
}

.table-height {
  overflow-y: auto;
  max-height: 400px;
}

.table-height td p {
  margin-bottom: 0;
}

.checkbox_group {
  overflow-y: auto;
  max-height: 300px;
  overflow-x: hidden;
  padding: 0 10px;
}

.checkbox_group .row .form-group {
  margin-bottom: 0;
}

.checkbox_group > .row .row {
  padding-left: 15px;
}

.image_popup .modal-body {
  padding-top: 15px;
  height: calc(100vh - 130px);
}

.play_podcast p,
p.cat_list {
  margin-bottom: 15px;
}

.play_podcast p {
  word-break: break-word;
}

p.cat_list strong {
  font-size: 16px;
}

p.cat_list strong:after {
  content: '|';
  padding: 0 8px;
  font-weight: 400;
}

p.cat_list strong.noSubCat::after {
  content: '';
  padding: 0;
}

p.cat_list span:after {
  content: ',';
  margin: 0 8px 0 0px;
}

.widget_box.card.red {
  background: red;
}

.ck-editor__top,
p.cat_list span:last-child::after {
  display: none;
}

.cast_box {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  background-color: var(--white-color);
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  margin-bottom: 15px;
}

.cast_box span.no_image,
.cast_box img {
  max-width: 120px;
  height: 120px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.play_popup .modal-header {
  padding: 0;
  border: none;
}

.play_popup.style_two .modal-header {
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

.play_popup .modal-body {
  height: auto;
  padding-top: 15px;
  padding-bottom: 0;
}

.play_podcast .image_box {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 15px;
  background-color: var(--color-one);
  padding: 15px;
}

.mat-slider-focus-ring {
  opacity: 1;
}

.mat-slider-thumb {
  border-color: var(--black-color) !important;
  background-color: var(--white-color);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-color: #eee;
  opacity: 1;
}

.mat-slider-thumb-label,
.mat-slider-track-fill {
  background-color: var(--color-one);
  z-index: 1;
}

.play_popup ngx-audio-player {
  margin: 0;
  width: 100%;
}

.play_popup .play-pause {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.table_accordion.table > thead > tr th {
  width: 20%;
}

.table_accordion.table > tbody > tr td {
  white-space: normal;
  padding: 0;
}

.table_accordion .accordion-item {
  border-radius: 0;
  border: none;
  background: transparent;
}

.table_accordion .accordion-item .accordion-header .accordion-button {
  background-color: transparent;
  border-radius: 0;
  border: none;
  padding: 0;
  box-shadow: none;
  color: inherit;
}

.table_accordion .accordion-item .accordion-header .accordion-button::after {
  content: '\F2E5';
  font-family: var(--bs-icon-font);
  background-image: none;
  color: var(--black-color);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 18px;
}

.table_accordion
  .accordion-item
  .accordion-header
  .accordion-button.collapsed::after {
  content: '\F4F9';
}

.table_accordion
  .accordion-item
  .accordion-header
  .accordion-button
  .toggle_btn {
  width: 100%;
  display: flex;
  align-items: center;
}

.table_accordion
  .accordion-item
  .accordion-header
  .accordion-button
  .toggle_btn
  .item {
  display: inline-block;
  width: 20%;
  text-align: left;
  padding: 0.5rem 0.5rem;
  font-size: 12px;
}

.table_accordion
  .accordion-item
  .accordion-header
  .accordion-button
  .toggle_btn
  p {
  margin-bottom: 0;
}

.accordion-body .table tbody tr td {
  padding: 0.5rem 0.5rem;
}

ngb-accordion > .accordion-item {
  background-color: #fff !important;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

ngb-accordion > .accordion-item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: var(--bs-table-striped-color);
}

.comments > li {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 2px solid #eee;
}

.comments > li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.comments li .image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-right: 15px;
  display: inline-block;
  vertical-align: top;
}

.comments li .text {
  position: relative;
  display: inline-block;
  width: calc(100% - 75px);
}

.comments li .trash {
  position: absolute;
  right: 0;
  top: 0;
}

.comments li > ul {
  padding-left: 75px;
}

.comments li > ul > li {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 2px solid #eee;
}

.customs_seach {
  position: relative;
}

.customs_seach .list-group.dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
  position: absolute;
  padding: 15px;
  width: 100%;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  margin-top: 5px;
  box-shadow: 0 1px 5px #959595;
  display: none;
}

.customs_seach > .form-control + .list-group > .form-control {
  margin-bottom: 15px;
}

.customs_seach .list-group.show,
.customs_seach.active > .form-control + .list-group {
  display: block;
}

.customs_seach .list-group .list-group-item {
  padding: 0;
  border: none;
}

.customs_seach .list-group .list-group-item label {
  display: block;
  padding: 6px 8px;
  cursor: pointer;
  margin-bottom: 5px;
}

.customs_seach .list-group .list-group-item label input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.customs_seach .list-group .list-group-item label > div {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0;
  color: #000;
}

.customs_seach .list-group .list-group-item label > div:before {
  box-sizing: content-box;
  content: '';
  color: #337ab7;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #337ab7;
  text-align: center;
  transition: 0.4s;
}

.customs_seach .list-group .list-group-item label > div:after {
  box-sizing: content-box;
  content: '';
  position: absolute;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 0.2s ease-out;
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.customs_seach .list-group .list-group-item label input:checked + div:before {
  animation: 0.2s ease-in borderscale;
  background: #337ab7;
}

.customs_seach .list-group .list-group-item label input:checked + div:after {
  content: '';
  transition: transform 0.2s ease-out;
  transform: rotate(-45deg) scale(1);
}

.customs_seach .list-group .list-group-item label:hover {
  background-color: #e4e3e3;
}

.customs_seach .list-group .list-group-item > .child-list {
  padding-left: 30px;
}

.customs_seach .selected_data {
  display: inline-block;
  position: relative;
}

.customs_seach .selected_data::after {
  display: none;
}

.customs_seach .selected_data span {
  border: 1px solid #337ab7;
  margin-right: 4px;
  background: #337ab7;
  padding: 0 5px;
  color: #fff;
  border-radius: 2px;
  float: left;
  max-width: 100%;
  margin-bottom: 3px;
  position: relative;
}

.customs_seach .selected_data span.subCat {
  border: 1px solid #663976;
  background: #663976;
}

.customs_seach .selected_data span > button {
  padding-left: 2px;
  color: white;
  position: relative;
  z-index: 9999;
}

.input-group.date_group .form-control {
  width: 100%;
}

.input-group.date_group .icon {
  bottom: 0px;
  top: auto;
}

.cast_crew_box {
  border: 1px solid #eee;
  padding: 5px;
  margin-bottom: 15px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  display: flex;
  position: relative;
}

.cast_crew_box .image_side {
  width: 100px;
  height: 100px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  margin-right: 10px;
}

.cast_crew_box .text_side {
  width: calc(100% - 110px);
  position: relative;
}

.cast_crew_box .form-group {
  margin-bottom: 0;
  line-height: 1.28;
}

.cast_crew_box .form-group label {
  font-size: 10px;
  margin-bottom: 0;
}

.cast_crew_box .form-group p {
  margin-bottom: 0px;
  font-size: 12px;
}

.cast_crew_box .action_area {
  position: absolute;
  top: 5px;
  right: 5px;
}

.cast_crew_box .dropdown .dropdown-toggle::after {
  display: none;
}

.cast_crew_img {
  height: 200px;
  width: 200px;
}

.adv_img {
  height: 130px;
  width: 200px;
}

.cast_crew_box.style_two .image_side {
  width: 75px;
  height: 75px;
}

.cast_crew_box.style_two .text_side {
  width: calc(100% - 105px);
}

.image_browse .form-control {
  padding-right: 15px;
  height: 43px;
}

.image_browse label {
  line-height: normal;
}

.image_browse .input-group-append input {
  display: none;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 9999999 !important;
}

.table tbody tr td .btn-icon.custom_icon {
  display: inline-block;
  padding: 4px;
}

.custom_icon span {
  display: flex;
  height: 2px;
  width: 100%;
  background-color: var(--white-color);
  margin-bottom: 4px;
}

.custom_icon span:last-child {
  margin-bottom: 0;
}

.mouse_disabled {
  pointer-events: none;
}

.reactiveFormSelect {
  position: relative;
}

.reactiveFormSelect p.form-control {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

.custom_checkbox label {
  display: block;
  padding: 6px 8px;
  cursor: pointer;
  margin-bottom: 5px;
}

.custom_checkbox label input[type='radio'],
.custom_checkbox label input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.custom_checkbox label > span {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0;
  color: #fff;
}

.custom_checkbox label > span:before {
  box-sizing: content-box;
  content: '';
  color: #337ab7;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #337ab7;
  text-align: center;
  transition: 0.4s;
}

.custom_checkbox label > span:after {
  box-sizing: content-box;
  content: '';
  position: absolute;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 0.2s ease-out;
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.custom_checkbox label input:checked + span:before {
  animation: 0.2s ease-in borderscale;
  background: #337ab7;
}

.custom_checkbox label input:checked + span:after {
  content: '';
  transition: transform 0.2s ease-out;
  transform: rotate(-45deg) scale(1);
}

.custom_checkbox label {
  /* background-color: #e4e3e3; */
  background-color: #0c1e2c;
  box-shadow: inset 0 14px 4px -12px rgba(0, 0, 0, 0.5),
    inset 0 9px 55px -22px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  position: relative;
}

.after_none {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.after_none:after {
  display: none;
}

@media (min-width: 768px) {
  .subCatPos {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 0px 0 15px;
  }
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled + .owl-dt-calendar-cell {
  cursor: default;
}

.owl-dt-calendar-table
  .owl-dt-calendar-cell-disabled
  + .owl-dt-calendar-cell
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  color: rgba(0, 0, 0, 0.4);
}

.multiFilter .input-group-prepend .form-control {
  padding-right: 30px;
}

.multiFilter .form-control:focus {
  background-color: #fff;
}

.cdk-drag {
  color: rgba(var(--black-color-rgb), 0.87);
  box-sizing: border-box;
  background: var(--white-color);
}

.cdk-drag > * {
  width: auto;
  max-width: auto;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.custom_checkbox.without_text label {
  padding: 0;
  margin-bottom: 0;
  line-height: 15px;
  display: inline-block;
}

.custom_checkbox.without_text label > span {
  padding-left: 18px;
}

.form-select.form-control:not(select) {
  padding-right: 40px;
}

.multiselect-dropdown
  .dropdown-btn
  .selected-item-container
  .selected-item
  span {
  white-space: normal;
}

.last_pipe::after {
  content: '|';
  padding: 0 3px;
}

.hide_removeBtn .selected-item a,
.last_pipe:last-child:after {
  display: none;
}

.switch input[type='checkbox' i]:disabled + .slider {
  cursor: default;
}

.table tbody tr td tr td {
  padding-left: 20px;
}

.table thead tr th tr th {
  padding-left: 15px;
}

.image_browse p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pac-container {
  top: auto !important;
  bottom: 150px;
}

.ck-editor__editable_inline {
  min-height: 300px !important;
}

.editorHeight .ck-editor__editable_inline {
  min-height: 100px !important;
}

.ck-editor__main p,
.htmlType p {
  margin-bottom: 5px;
}

.htmlType p {
  text-align: justify;
}

.ck-editor__main .ck-editor__editable,
.details_lable label {
  font-size: 16px;
}

.ck-editor__main .ck-editor__editable {
  border-color: var(--color-one) !important;
}

.ck-editor__main .ck-editor__editable.ck-focused {
  box-shadow: none !important;
}

.ck-editor__main .ck-editor__editable,
.ck-editor__main .ck-placeholder::before {
  color: var(--color-one) !important;
}

.no_image {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: var(--color-two);
  color: var(--white-color);
  font-size: 46px;
  font-weight: 600;
}

.table tbody tr td .cat_list {
  margin-bottom: 10px;
  white-space: nowrap;
}

.table tbody tr td .cat_list strong {
  font-size: 14px;
}

.table tbody tr td .cat_list:last-child {
  margin-bottom: 0;
}

.d-flex .link {
  word-break: normal;
}

.cast_crew_box .link {
  white-space: normal;
}

.line-break {
  white-space: pre-line;
}
.word-break {
  white-space: pre-line;
  word-break: break-all;
}

.table.no_wrap tbody tr td button.link {
  margin-left: 4px;
}

.htmlDescription a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

.fixedTable {
  position: absolute;
  width: 35px;
  left: 15px;
  top: auto;
  display: flex;
  align-items: center;
}

.modal-body .link {
  white-space: normal;
}

.cast_box p.cat_list span:after {
  margin-right: 0;
}

.resize-none {
  resize: none;
}

.link.wrap_text,
.wrap_text {
  white-space: normal;
}

.chooseTwoTypeImage .tab-content {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.chooseTwoTypeImage .image_browse label {
  padding: 0;
}

.chooseTwoTypeImage .image_browse label img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chooseTwoTypeImage .image_browse label input:checked + span {
  display: block;
  background: #ba6da7;
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  z-index: -1;
}

.viewData {
  cursor: pointer;
}
.table-responsive .table td .copy {
  cursor: pointer;
  margin-left: 5px;
}
.table-responsive .table td .copy:hover {
  color: var(--color-two);
}

@media screen and (max-width: 991px) {
  .table-responsive .table tr {
    display: block;
  }

  .table-responsive .table th {
    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table-responsive .table th,
  .table-responsive .table td {
    display: flex;
    font-size: 1em;
    text-align: right;
    min-height: 40px;
    line-height: 38px;
    align-items: center;
    justify-content: space-between;
  }

  .table-responsive .table th:before,
  .table-responsive .table td:before {
    content: attr(data-label);
    float: left;
    /* text-transform: capitalize; */
    font-weight: bold;
    font-size: 0.85em;
    margin-right: 20px;
    max-width: 48%;
    text-align: left;
  }

  .table-responsive .table thead th[data-label] {
    position: relative;
    width: auto;
    border: #000;
    padding: 0 10px;
  }

  .table-responsive .table td[colspan] {
    justify-content: center;
  }
}

.cdk-global-scrollblock {
  overflow-y: hidden;
}
.input-group-text {
  &.custom_checkbox {
    padding: 0;
    border: 1px solid var(--color-one);
    border-radius: 0;
    justify-content: start;
    label {
      margin: 0;
      height: 100%;
      width: 100%;
      text-align: left;
    }
  }
}
/* .table-responsive {
  background-color: #0c1e2c;
  box-shadow: 0 14px 4px -12px rgba(0, 0, 0, 0.5),
    0 9px 55px -22px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-bottom: 15px;
  border: 1px solid #273b4b;
} */
.table-responsive .table {
  margin-bottom: 0;
}
.table-responsive {
  overflow-y: visible;
  margin-bottom: 15px;
  /* background-color: var(--color-two); */
  color: var(--white-color);
  /* text-align: center; */
  border: 0.1rem solid transparent;
  outline: none;
  background-image: linear-gradient(#0c1e2c, #0c1e2c),
    linear-gradient(315deg, #3eb7f9, #0abab5);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}

.table-responsive:after {
  content: '';
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  background-color: #00dbde;
  background-image: linear-gradient(315deg, #3eb7f9, #0abab5);
  filter: blur(0.8rem);
  transition: 0.5s ease;
}
.table-responsive .inner {
  all: unset;
  background-color: #0c1e2c;
  box-shadow: inset 0 0.7rem 0.6rem rgba(0, 0, 0, 0.27);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 10px;
}
.table thead tr th:first-child {
  border-top-left-radius: 10px;
}
.table thead tr th:last-child {
  border-top-right-radius: 10px;
}
.table tbody tr:last-child td {
  border-bottom: none;
}
.table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.table tbody tr td .action_wrap {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.text-group {
  margin-bottom: 15px;
  color: var(--white-color);
}
hr {
  border-color: #273b4b;
  opacity: 1;
}
.custom-modal .modal-header {
  background-color: #0c1e2c;
  border-radius: 10px 10px 0px 0px;
  border-color: #273b4b;
}
.custom-modal .modal-body {
  background-color: #0c1e2c;
  border-radius: 0px 0px 10px 10px;
}
.custom_tabs .nav {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  max-width: 500px;
  height: auto;
  background-color: #0c1e2c;
  padding: 2px;
  border-radius: 33px;
  box-shadow: -6px -6px 6px #1f3d55;
  position: relative;
  margin-bottom: 20px;
}
.custom_tabs .nav::after {
  content: '';
  max-width: 500px;
  height: 100%;
  border-radius: 33px;
  box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.325);
  position: absolute;
  left: 0;
  top: 0;
}
.custom_tabs .nav.full-width::after,
.custom_tabs .nav.full-width {
  max-width: 100%;
  width: 100%;
}
.custom_tabs .nav .nav-link {
  all: unset;
  font-size: 16px;
  color: hsla(0, 0%, 100%, 0.6);
  position: relative;
  z-index: 1;
  width: 50%;
  text-align: center;
  padding: 12px 6px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom_tabs .nav .nav-link.active {
  color: #fff;
  border-radius: 23px;
  box-shadow: inset 0.5rem 0.7rem 0.6rem rgba(0, 0, 0, 0.38);
}
.custom_tabs .nav .nav-link.active::after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 23px;
  box-shadow: inset -0.5rem -0.6rem 1rem hsla(0, 0%, 81%, 0.16);
  position: absolute;
  left: 0;
  top: 0;
}
.alert-dark {
  position: relative;
  margin-bottom: 30px;
  background-color: #0c1e2c;
  box-shadow: inset 0 14px 4px -12px rgba(0, 0, 0, 0.5),
    inset 0 9px 55px -22px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: none;
  color: var(--white-color);
}
.link-color {
  color: #0abab5;
  text-decoration-line: underline;
}

.badge {
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: inset 0 14px 4px -12px rgba(0, 0, 0, 0.5),
    inset 0 9px 55px -22px rgba(0, 0, 0, 0.5);
}
.job-card.card {
  border: 0.1rem solid transparent;
  outline: none;
  background-image: linear-gradient(#0c1e2c, #0c1e2c),
    linear-gradient(315deg, #3eb7f9, #0abab5);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}
.job-card.card .inner {
  background-color: #0c1e2c;
  box-shadow: inset 0 0.7rem 0.6rem rgba(0, 0, 0, 0.27);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 10px;
}
.job-card.card .card-header .card-title {
  font-size: 16px;
}
.job-card.card:after {
  content: '';
  border-radius: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  background-color: #00dbde;
  background-image: linear-gradient(315deg, #3eb7f9, #0abab5);
  filter: blur(0.8rem);
  transition: 0.5s ease;
}
.payment-card .alert-heading {
  border-bottom: 1px solid;
  padding-bottom: 1rem;
  border-bottom: var(--bs-card-border-width) solid #273b4b;
}
.cursor-pointer {
  cursor: pointer;
}
.details_style .text-group:not(.no-detail-style) {
  border-bottom: var(--bs-card-border-width) solid #273b4b;
  padding-bottom: 15px;
}
.details_style .text-group label {
  font-weight: 600;
}
.input-with-btn {
  position: relative;
}
.input-with-btn .btn {
  position: absolute;
  top: 40px;
  right: -40px;
  z-index: 1;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__close-icon.btn.btn-sm {
  height: 42px;
  width: 42px;
  z-index: 6;
  padding: 0;
  top: 3.5px;
  right: 3.5px;
}
.react-datepicker__close-icon:after {
  height: auto;
  width: auto;
  padding: 0;
  background: transparent;
  font-size: 18px;
  content: '\f659';
  font-family: var(--bs-icon-font);
}
.accordion .card:last-child {
  margin-bottom: 0;
}
div:where(.swal2-icon) {
  border-color: white !important;
}
.btn:disabled {
  opacity: 0.7;
}
.min-height-form {
  overflow-y: auto;
  height: 385px;
}
@media screen and (max-width: 991px) {
  .table tbody tr:last-child td:first-child {
    border-radius: 0px;
  }
  .table.no_wrap tbody tr td {
    border-right: none;
  }
  .table tbody tr:not(:last-child) {
    border: none;
    border-bottom: 5px solid #273b4b;
  }
  .table tbody tr td {
    border: none;
    border-top: 1px solid #273b4b;
  }
  .table tbody tr:first-child td:first-child {
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: none;
  }
  .table tbody tr:last-child td:last-child {
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .table tbody tr td.text-center {
    border-radius: 10px !important;
  }
}
